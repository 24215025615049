*,
*::before,
*::after {
  box-sizing: border-box;
  outline: 0!important;
}

html {
  font-size: 100%;
}

body {
  font-family: arial, sans-serif;
}

/* .pagination margin */
.panel .pagination {
  margin: 0;
}

/* Form Horizontal Fix */
.form-horizontal label.control-label {
  text-align: left;
}

::-webkit-scrollbar {
  height: 16px;
  overflow: visible;
  width: 16px;
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  min-height: 28px;
  padding: 100px 0 0;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.07);
  box-shadow: inset 1px 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.07);
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}