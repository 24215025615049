.controller {
  height: 42px!important;
  padding-right: 30px;
  border-bottom: 1px solid #e5e5e5;
  overflow: hidden;

  .left, .right{
    overflow: hidden;
    > *:not(:last-child) {
      margin-right: 16px;
      float: left;
    }
  }

  .left {
    float: left;
  }

  .right {
    float: right;
  }

  .page-ctrl {
    font-size: 14px;
    height: 32px;
    padding: 9px;
    line-height: 1;
    border: none;
    background: none;
  }

  button {
    border: 1px solid rgba(0, 0, 0, 0.0980392);
    font-size: 0.875rem;
    height: 32px;
  }

  button.btn.btn-default {
    background: #eee;
  }

  button.page-ctrl:hover {
    background: #eee;
  }

  button.page-ctrl.active {
    background: #ddd;
  }

  button.w-36 {
    width: 36px;
  }

  button.w-80 {
    width: 80px;
  }

  button .fa {
    margin-top: 3px;
    font-size: 15px;
  }

  button .caret {
    margin-left: 10px;
  }
}