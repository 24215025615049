.globaline-ui-reset {
  font-size: 12px;
  font-weight: normal;
  color: #4c4948;
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

/**
  Original size of input
 */
$padding-original-vertical: 2px !default;
$padding-original-horizontal: 8px !default;
$input-height-original: (floor($font-size-small * $line-height-small) + ($padding-original-vertical * 2) + 2) !default;

$input-height-xs: (floor($font-size-small * $line-height-small) + ($padding-xs-vertical * 2) + 2) !default;

.input-gl {
  height: $input-height-original;
  padding: $padding-original-vertical $padding-original-horizontal;
  font-size: $font-size-small;
  line-height: $line-height-small;
  border-radius: $border-radius-small;
}



.input-group-gl > .form-control,
.input-group-gl > .input-group-addon,
.input-group-gl > .input-group-btn > .btn {
  height: $input-height-original;
  padding: $padding-original-vertical $padding-original-horizontal;
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.input-xs {
  height: $input-height-xs;
  padding: $padding-xs-vertical $padding-xs-horizontal;
  font-size: $font-size-small;
  line-height: $line-height-small;
  border-radius: $border-radius-small;
}

.btn-gl {
  @include button-size($padding-original-vertical, $padding-original-horizontal, $font-size-small, $line-height-small, $btn-border-radius-small);
}

.input-group-xs > .form-control,
.input-group-xs > .input-group-addon,
.input-group-xs > .input-group-btn > .btn {
  height: $input-height-xs;
  padding: $padding-xs-vertical $padding-xs-horizontal;
  font-size: $font-size-small;
  line-height: $line-height-small;
}

.ui-tab-header-item {
  text-transform: none;
}